import {Button, Modal, Form} from "react-bootstrap";
import {t} from "ttag";
import {createRoot} from 'react-dom/client'
import React, {
  ChangeEvent, KeyboardEvent,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState
} from "react";
import Editor from 'react-simple-wysiwyg';

function ConfirmationDialog({closeDialog, label, text, confirmText} : {
  closeDialog: (approved: boolean) => void,
  label: string,
  text: string,
  confirmText: string
}) {
  return <Modal show={true} onHide={() => closeDialog(false)}>
    <Modal.Header closeButton>
      <Modal.Title>{label}</Modal.Title>
    </Modal.Header>
    <Modal.Body><div dangerouslySetInnerHTML={{__html: text}}/></Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => closeDialog(false)}>
        {t`Close`}
      </Button>
      <Button variant="primary" onClick={() => closeDialog(true)}>
        {confirmText}
      </Button>
    </Modal.Footer>
  </Modal>
}

export function confirmDialog({label, text, buttonText = t`Confirm`, onConfirm} : {
  label: string,
  text: string,
  buttonText?: string,
  onConfirm?: () => void
}) {
  let resolver : (value: boolean) => void
  const returnedPromise = new Promise<boolean>(resolve => {
    resolver = resolve
  })

  const container = document.createElement("div");
  const root = createRoot(container);
  async function onClose(accepted : boolean) {
    root.unmount();
    container.remove();
    if(accepted && onConfirm) {
      await onConfirm();
    }
    resolver(accepted)
  }

  root.render(<ConfirmationDialog closeDialog={onClose} label={label} text={text} confirmText={buttonText} />);
  return returnedPromise;
}

function PromptDialog({closeDialog, label, text, confirmText, defaultValue, setValue, richText, options}: {
  closeDialog: (approved: boolean) => void,
  label: string,
  text: string,
  confirmText?: string,
  defaultValue: string,
  setValue: (val: string) => void,
  richText?: boolean,
  options?: ReactNode[]
}) {
  const [state, setState] = useState(defaultValue);
  // https://stackoverflow.com/a/61980548
  const inputRef = useRef<HTMLElement>(null);
  useEffect(() => {
    inputRef.current && inputRef.current.focus()
  });

  function updateValue(val : string) {
    setValue(val)
    setState(val)
  }

  const baseFormProps = {
    value: state,
    onChange: (e : ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => updateValue(e.target.value),
    onKeyUp: (e : KeyboardEvent) => {
      if (e.key === 'Enter'){
        closeDialog(true);
      }
    }
  }

  let control;
  if(options) {
    control = <Form.Control ref={inputRef as RefObject<HTMLSelectElement>} as="select" {...baseFormProps}>{options}</Form.Control>
  } else if(richText) {
    control = <Editor value={state} onChange={(e) => updateValue(e.target.value)} />
  } else {
    control = <Form.Control ref={inputRef as RefObject<HTMLInputElement>}  {...baseFormProps} />
  }

  return <Modal size={richText ? 'lg' : 'sm'} show={true} onHide={() => closeDialog(false)}>
    <Modal.Header closeButton>
      <Modal.Title>{label}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {text}
      <Form.Group>
        {control}
      </Form.Group>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => closeDialog(false)}>
        {t`Close`}
      </Button>
      <Button variant="primary" onClick={() => closeDialog(true)}>
        {confirmText}
      </Button>
    </Modal.Footer>
  </Modal>
}

export function promptDialog({label, text, buttonText, defaultValue = '', onSubmit, richText, options} : { label: string,
  text: string,
  buttonText?: string,
  defaultValue?: string,
  onSubmit?: (val: string) => void,
  richText?: boolean,
  options?: ReactNode[]
}) {
  let resolver : (value: string | null) => void
  const returnedPromise = new Promise<string | null>(resolve => {
    resolver = resolve
  })

  const state = {
    value: defaultValue
  }

  const container = document.createElement("div");
  const root = createRoot(container);
  async function onClose(accepted : boolean) {
    root.unmount();
    container.remove();
    if(accepted && onSubmit) {
      await onSubmit(state.value);
    }
    resolver(accepted ? state.value : null)
  }

  root.render(<PromptDialog closeDialog={onClose} label={label} text={text} confirmText={buttonText} defaultValue={defaultValue} richText={richText} setValue={(val) => state.value = val} options={options}/>);
  return returnedPromise;
}

function MessageDialog({closeDialog, label, text, dangerous = false}  : {
  closeDialog: () => void,
  label: string,
  text: string,
  dangerous?: boolean
}) {
  return <Modal show={true} onHide={() => closeDialog()}>
    <Modal.Header closeButton>
      <Modal.Title>{label}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{dangerous ? <div dangerouslySetInnerHTML={{__html: text}}/>: text}</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => closeDialog()}>
        {t`OK`}
      </Button>
    </Modal.Footer>
  </Modal>
}

export function messageDialog({label, text, dangerous = false} : {
  label: string,
  text: string,
  dangerous? : boolean
}) {
  const container = document.createElement("div");
  const root = createRoot(container);
  async function onClose() {
    root.unmount()
    container.remove();
  }

  root.render(<MessageDialog closeDialog={onClose} label={label} text={text} dangerous={dangerous}/>);
}